import { checkProjectAccess } from 'Reducers/global';
import { SAMLSkipFlag } from 'Routes/feature';
import { PathUrls } from 'Routes/pathUrls';
import { message } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import UiMessage from 'Components/UiMessage';
import { getReCaptchaSiteKey } from './request';
import logger from './logger';

export const EMPTY_FUNCTION = () => {};

export const EMPTY_STRING = '';

export const EMPTY_OBJECT = {};

export const EMPTY_ARRAY = [];

export const isStringLengthValid = (str, length = 1) =>
  _.size(_.trim(str)) >= length;

export const groupFilters = (array, key) =>
  // Return the end result
  array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object

export const compareFilters = (a, b) => {
  if (a.ref < b.ref) return -1;
  if (a.ref > b.ref) return 1;
  return 0;
};

export const toCapitalCase = (str) => {
  const lower = str.toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
};

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const abbreviateNumber = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`;
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`;
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`;
  if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`;
  return null;
};

export function generateRandomKey(length = 8) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function insertUrlParam(history, key, value) {
  if (history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${searchParams.toString()}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

export function isOnboarded(currentProjectSettings) {
  return (
    currentProjectSettings?.onboarding_steps?.more_info_form &&
    currentProjectSettings?.onboarding_steps?.project_created &&
    currentProjectSettings?.onboarding_steps?.sdk_setup &&
    currentProjectSettings?.onboarding_steps?.setup_completed &&
    currentProjectSettings?.onboarding_steps?.visitor_identification_setup
  );
}

export function getErrorMessage(resultState) {
  let errorMessage = '';

  if (resultState?.status === 500) {
    errorMessage =
      'The server encountered an internal error and could not complete your request';
  } else if (resultState?.status === 400) {
    errorMessage = '400 Bad Request. Please check your request parameters';
  } else if (resultState?.status === 404) {
    errorMessage = 'Resource Not Found! Please check your request.';
  } else {
    // check for no data found
    if (resultState?.status === 200) {
      if (!resultState?.data || resultState?.data?.length === 0) {
        errorMessage = 'No Data Found! Try a Different Time Range';
      } else if (resultState?.data?.metrics?.rows?.length === 0) {
        errorMessage = 'No Data Found! Try Changing Filters or Time Range';
      }
    } else {
      errorMessage =
        'We are facing trouble loading UI. Drop us a message on the in-app chat';
      if (resultState?.status === 206) {
        errorMessage = _.get(
          resultState,
          'data.data.result_group[0].rows[0][0]',
          errorMessage
        );
      }
    }
  }

  return errorMessage;
}

export function getCookieValue(cookieName) {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    const cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  // Return null if the cookie with the specified name is not found
  return null;
}

export function getASCIISum(str) {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    for (let j = 0; j < str[i].length; j++) {
      const char_code = str[i].charCodeAt(j);
      sum += char_code;
    }
  }
  return sum;
}

export async function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function CopyTextToClipboard(text = '') {
  try {
    await navigator.clipboard.writeText(text);
    message.success('Successfully copied');
  } catch (err) {
    message.error('Failed to copy!');
  }
}

export async function checkAccessBasedForCurrentProject(
  sso_state,
  activeProject
) {
  if (SAMLSkipFlag) {
    const result = await isSAMLTransitionAllowed(activeProject?.id);
    if (result) return true;
  }

  switch (sso_state) {
    case 1:
      return activeProject?.sso_state === 1;
    case 2:
      return activeProject?.sso_state === 2;
    case 3:
      return activeProject?.sso_state === 1 || activeProject?.sso_state === 3;
    case false:
      return false;

    default:
      return undefined;
  }
}

// THis checks if we have access to either of the prjects
// IF we don't this locks us to ProjectAuthChange Page
// And not Allows user to move to any other page
export function accessAuthCheck(sso_state, projects) {
  switch (sso_state) {
    case 1:
      return projects.find((e) => e.sso_state === 1);
    case 2:
      // Need Change, we should Check for only that project for which he have access ( I mean by token )
      // This case will come up later.
      // We can't randomly assign to any SAML project
      return projects.find((e) => e.sso_state === 2);
    case 3:
      return projects.find((e) => e.sso_state === 1 || e.sso_state === 3);
    case 4:
      return projects.find((e) => e.sso_state === 1 || e.sso_state === 4);
    case 0:
      return false;
    default:
      return undefined;
  }
}

export async function isSAMLTransitionAllowed(selectedProjectID) {
  try {
    const response = await checkProjectAccess(selectedProjectID);
    if (response.ok) return true;
    return false;
  } catch (error) {
    return false;
  }
}
export async function haveRestrictionForSelectedProject(
  currentMethod,
  selectedMethod,
  selectedProjectID
) {
  if (SAMLSkipFlag) {
    const result = await isSAMLTransitionAllowed(selectedProjectID);
    if (result) return true;
  }

  switch (currentMethod) {
    case 1:
      return selectedMethod === 1;
    case 2:
      return false;
    case 3:
      return selectedMethod === 1 || selectedMethod === 3;
    case 4:
      return selectedMethod === 1 || selectedMethod === 4;
    default:
      return undefined;
  }
}

export function isValidLoginMethod(sso_state) {
  return sso_state == 1 || sso_state == 2 || sso_state == 3 || sso_state == 4;
}
export const WHITELIST_INTERMEDIATE_STATES = new Set();
WHITELIST_INTERMEDIATE_STATES.add(PathUrls.ProjectChangeAuthentication);
WHITELIST_INTERMEDIATE_STATES.add(PathUrls.Onboarding);
WHITELIST_INTERMEDIATE_STATES.add(PathUrls.TwoFactorsAuthentication);

export function updateAgentLoginMethod(method_type) {
  localStorage.setItem('sso_state', method_type);
}
export function getLoginMethod() {
  const value = localStorage.getItem('sso_state');
  if (value && Number(value)) {
    return Number(value);
  }
  return null;
}

export function updateG2ReviewModal(val) {
  localStorage.setItem('g2_review', val);
}
export function isG2ReviewEnabled() {
  const value = localStorage.getItem('g2_review');
  if (value === 'false') {
    return false;
  }
  if (value === 'true') {
    return true;
  }
  return null;
}

export const RemoveStepConversionTime = false;

// Calculate the valid range (last N months to today)
export function isRangeWithinLastNMonths(start, end, N = 6) {
  let startDate = start;
  let endDate = end;
  if (!moment.isMoment(startDate)) {
    startDate = moment(startDate);
  }
  if (!moment.isMoment(endDate)) {
    endDate = moment(endDate);
  }
  const today = moment();
  const earliestValidDate = moment().subtract(N, 'months');

  const isStartDateValid = startDate.isBetween(
    earliestValidDate,
    today,
    'days',
    '[]'
  );
  const isEndDateValid = endDate.isBetween(
    earliestValidDate,
    today,
    'days',
    '[]'
  );
  // Making sure startDate <= endDate
  const isRangeValid = startDate.isSameOrBefore(endDate);

  if (isStartDateValid && isEndDateValid && isRangeValid) {
    return true;
  }

  return false;
}

export const AnyPageViewOutOfRangePopupError = `For ‘Any Page View’ event, the query date range must fall within the last 6 months from today
`;
// Returns the JS SDK required to enable Recaptcha
export const GetRecaptchaResourceURL = () => {
  const siteKey = getReCaptchaSiteKey();
  return `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
};

export function getRecaptchaToken(action = 'submit') {
  return new Promise((resolve, reject) => {
    if (!window.grecaptcha?.enterprise) {
      reject(new Error('Failed to get ReCaptcha Object'));
      return;
    }
    window.grecaptcha.enterprise.ready(() => {
      const siteToken = getReCaptchaSiteKey();

      window.grecaptcha.enterprise
        .execute(siteToken, { action })
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

export function HandleGoogleRecaptchaOnLoad() {
  if (!window?.grecaptcha?.enterprise) {
    if (Sentry) Sentry.captureMessage('Something went wrong', 'error');
    logger.error('Google Recaptcha failed to load');
    UiMessage.error('Google Recaptcha failed to load');
  }
}

export function HandleGoogleRecaptchaRemove() {
  if (window.grecaptcha?.enterprise) {
    const allBadges = document.querySelectorAll('.grecaptcha-badge');
    allBadges.forEach((eachBadge) => {
      eachBadge.remove();
    });
    delete window.grecaptcha.enterprise;
  }
}

export function HandleGoogleRecaptchaAction(callback = () => {}) {
  window.grecaptcha?.enterprise.ready(async () => {
    const RecaptchaSiteKey = getReCaptchaSiteKey();
    const token = await window.grecaptcha.enterprise?.execute(
      RecaptchaSiteKey,
      {
        action: 'login'
      }
    );
    callback(token);
  });
}
